import React, { useEffect, useState } from "react";
import { CChart } from "@coreui/react-chartjs";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Chart from "react-apexcharts";

const GraphData = ({ graphState, onDurationChange, durationType }) => {
  const dateArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Sunday",
  ];
  const [duration, setDuration] = useState({
    labels: [],
    tenantData: [],
    landlordData: [],
    propertiesData: [],
  });
  // const [durationType, setDurationType] = useState("weekly")

  // useEffect(() => {
  //   onDurationChange(durationType)
  //   changeDuration(durationType);
  //   // console.log("Duration changed")
  // }, [durationType])

  useEffect(() => {
    changeDuration(durationType);
  }, []);

  const setDurationType = (e) => {
    onDurationChange(e.target.getAttribute("data-attr"));
  };

  const [propertiesChart, setPropertiesChart] = useState({
    labels: [],
    data: [],
  });

  const getDayName = (value) => {
    const dateArray = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return dateArray[value];
  };

  const getArrangedData = () => {
    let tenant = [];
    let landlord = [];
    let property = [];
    for (let day of graphState.labels) {
      tenant.push(graphState.tenantData[day]);
      landlord.push(graphState.landlordData[day]);
      property.push(graphState.propertyData[day]);
    }
    return { tenant, landlord, property };
  };

  const changeDuration = (value) => {
    // if (value == "weekly") {
    const arrVals = getArrangedData();
    console.log(arrVals);
    setDuration({
      labels: graphState.labels,
      tenantData: arrVals.tenant,
      landlordData: arrVals.landlord,
      propertiesData: arrVals.property,
    });
  };

  let provinceData = [];

  graphState.totalProvince.forEach((data) => {
    provinceData.push({ x: data._id, y: data.count });
  });

  let option = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    series: [
      {
        data: provinceData,
      },
    ],
  };

  return (
    <>
      <button
        onClick={setDurationType}
        data-attr="daily"
        className={`btn ${durationType == "daily" ? "active" : ""}`}
      >
        Daily
      </button>
      <button
        onClick={setDurationType}
        data-attr="weekly"
        className={`btn ${durationType == "weekly" ? "active" : ""}`}
      >
        Weekly
      </button>
      <button
        onClick={setDurationType}
        data-attr="monthly"
        className={`btn ${durationType == "monthly" ? "active" : ""}`}
      >
        Monthly
      </button>
      {/* <button onClick={() => changeDuration('yearly')} className='btn'>Yearly</button> */}

      <div className="admin-chart">
        <Row>
          {/* tenant and landlord data */}
          <Col md={12}>
            <CChart
              type="line"
              style={{ width: "100% !important" }}
              data={{
                labels: duration?.labels,
                datasets: [
                  {
                    label: "Tenants",
                    backgroundColor: "rgba(241, 60, 0, 0.8)",
                    borderColor: "rgba(241, 60, 0, 0.8)",
                    pointBackgroundColor: "rgba(241, 60, 0, 1)",
                    pointBorderColor: "#fff",
                    data: duration.tenantData,
                  },
                  {
                    label: "Landlords",
                    backgroundColor: "rgba(1, 1, 1, 0.84)",
                    borderColor: "rgba(1, 1, 1, 0.84)",
                    pointBackgroundColor: "rgba(1, 1, 1, 1)",
                    pointBorderColor: "#fff",
                    data: duration.landlordData,
                  },
                ],
              }}
            />
          </Col>

          {/* city chart */}
          {/* <Col md={3}>
            <div className='chart-wrapper bar-2 h-100'>
              <Chart
                height={500}
                options={option}
                series={option.series}

                type="bar"
              /></div>
          </Col> */}
        </Row>

        <Row className="bottom_charts">
          {/* property charts */}
          <Col md={6}>
            <div className="bar_chart">
              <CChart
                type="bar"
                data={{
                  labels: duration?.labels,
                  datasets: [
                    {
                      label: "Properties",
                      backgroundColor: "#f87979",
                      data: duration?.propertiesData,
                    },
                  ],
                }}
                labels="months"
              />
            </div>
          </Col>

          <Col md={6}>
            <div className="donut_chart">
              {/* tenants and users charts */}
              <CChart
                type="doughnut"
                radius="20%"
                height={300}
                spacing={10}
                data={{
                  labels: ["Tenants", "Landlords"],
                  datasets: [
                    {
                      backgroundColor: ["#121212", "#DE4724"],
                      data: [
                        graphState?.totalTenantCount,
                        graphState?.totalLandlordCount,
                      ],
                    },
                  ],
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GraphData;
