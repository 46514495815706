import { Col, Row } from "react-bootstrap";
import tickNew from "../../icons/tick-new.svg";
import { useNavigate } from "react-router-dom";
import { MdInfoOutline } from "react-icons/md";

const ProfileInfoSection = (props) => {
  const {
    user,
    userKycInfo,
    setShowPhoneVerificationdModal,
    handleRemoveGuarantor,
    handleGurantorModal,
    showActionPrompts = false,
  } = props;
  const navigate = useNavigate();
  const haveValue = (val) => {
    if (
      val !== "" &&
      val !== "undefined" &&
      val !== undefined &&
      val !== null &&
      val !== "null"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const _t = (val) => {
    return val;
  };

  return (
    <>
      <Col xl={6} className="mb-3">
        <div className="score-personal-info border-pd accordion-tile">
          <h4>{_t("Personal Info")}</h4>
          <Row className="mx-0">
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("Name")} :</h4>
                {user?.onfido_user_kyc?.onfido_doc_data?.first_name ? (
                  <h3>
                    {`${user?.onfido_user_kyc?.onfido_doc_data?.first_name} ${user?.onfido_user_kyc?.onfido_doc_data?.last_name}`}{" "}
                    <img src={tickNew} alt="" />
                  </h3>
                ) : user?.fname ? (
                  <h3>{`${user?.fname} ${user?.lname}`}</h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
                <h3></h3>
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("Nationality")}</h4>
                {user?.nationality ||
                haveValue(
                  user?.onfido_user_kyc?.onfido_doc_data?.nationality
                ) ? (
                  <h3>
                    {haveValue(
                      user?.onfido_user_kyc?.onfido_doc_data?.nationality
                    ) ? (
                      <>
                        {user?.onfido_user_kyc?.onfido_doc_data?.nationality}{" "}
                        <img src={tickNew} alt="" />
                      </>
                    ) : (
                      _t(user?.nationality)
                    )}
                  </h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("Passport ID")}</h4>
                {user?.passport_id ||
                (haveValue(userKycInfo?.onfido_doc_data?.document_number) &&
                  userKycInfo?.onfido_doc_data?.document_type == "passport") ? (
                  <h3>
                    {haveValue(
                      userKycInfo?.onfido_doc_data?.document_number
                    ) ? (
                      <>
                        {userKycInfo?.onfido_doc_data?.document_number}
                        <img src={tickNew} alt="" />
                      </>
                    ) : (
                      user?.passport_id
                    )}
                  </h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("DNI/NIE")}:</h4>
                {user?.dni_nie ||
                haveValue(userKycInfo?.onfido_doc_data?.personal_number) ? (
                  <h3>
                    {haveValue(
                      userKycInfo?.onfido_doc_data?.personal_number
                    ) ? (
                      <>
                        {" "}
                        {userKycInfo?.onfido_doc_data?.personal_number}
                        <img src={tickNew} alt="" />
                      </>
                    ) : (
                      user?.dni_nie
                    )}
                  </h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("Phone Number")}</h4>
                {user?.phone_number ? (
                  <>
                    <h3 className="rounded-check summaryinfobar px-0">
                      +{`${user?.phone_number}`}
                      {user?.phone_number_verified && (
                        <img src={tickNew} alt="" />
                      )}
                      {user?.phone_number_verified !== true &&
                        showActionPrompts && (
                          <ul className="infodorpdown summarydropdum">
                            <li>
                              <button className="iconinfo">
                                <MdInfoOutline />
                              </button>
                            </li>
                            <ul className="tooltip">
                              <li>
                                <p>
                                  {_t(
                                    "You haven’t verified your phone number yet"
                                  )}
                                </p>
                                <button
                                  className="btnupdate"
                                  onClick={() => {
                                    setShowPhoneVerificationdModal(true);
                                  }}
                                >
                                  {_t("Verify  Now")}
                                </button>
                              </li>
                            </ul>
                          </ul>
                        )}
                    </h3>
                  </>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("Flatmates")}:</h4>
                {haveValue(user?.sharers) ? (
                  <h3 className="setNoInfoColor text-start">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) =>
                          navigate("/edit-profile/tenant#add_flatmate_id")
                        }
                      >
                        {user?.sharers.length} {_t("Flatmates")}
                      </a>
                    ) : (
                      <>
                        {user?.sharers.length} {_t("Flatmates")}
                      </>
                    )}{" "}
                  </h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Flatmates")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="Score-content-list">
                <h4>{_t("Email")}</h4>
                {user?.email ? (
                  <div className="email_flex">
                    <h3>{user?.email}</h3>
                    <img src={tickNew} alt="" />
                  </div>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            {(user?.have_gurantor == "true" &&
              haveValue(user?.gurantor_name) &&
              haveValue(user?.gurantor_email)) ||
            showActionPrompts ? (
              <div className="gurantor_box">
                <h4>{_t("Guarantor Details")}</h4>

                <Row>
                  {user?.have_gurantor == "true" &&
                  haveValue(user?.gurantor_name) &&
                  haveValue(user?.gurantor_email) ? (
                    <>
                      <Col md={6}>
                        <div className="Score-content-list">
                          <h4 className="d-flex align-items-center gap-1">
                            {_t("Name Of Guarantor")}
                            {!haveValue(user?.gurantor_id) &&
                              haveValue(user?.gurantor_email) &&
                              showActionPrompts && (
                                <label className="">
                                  <ul className="infodorpdown summarydropdum position-relative">
                                    <li>
                                      <button className="iconinfo guarantor_tooltip">
                                        <MdInfoOutline />
                                      </button>{" "}
                                    </li>
                                    <ul className="tooltip">
                                      <li>
                                        {_t(
                                          "Waiting for Guarantor to accept your request"
                                        )}
                                        .
                                      </li>
                                    </ul>
                                  </ul>
                                </label>
                              )}
                          </h4>

                          {user?.gurantor_name ? (
                            <>
                              <h3>{user?.gurantor_name}</h3>
                            </>
                          ) : (
                            <h3>{_t("No Guarantor")}</h3>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="Score-content-list">
                          <h4 className="d-flex align-items-center">
                            {_t("Guarantor Email")}:
                          </h4>
                          {user?.gurantor_email ? (
                            <h3>{user?.gurantor_email} </h3>
                          ) : (
                            <h3 className="setNoInfoColor">
                              {showActionPrompts ? (
                                <a
                                  href={undefined}
                                  onClick={(e) =>
                                    navigate("/edit-profile/tenant")
                                  }
                                >
                                  {_t("+Add Info")}
                                </a>
                              ) : (
                                <p href={undefined}>{_t("No Info")}</p>
                              )}
                            </h3>
                          )}
                        </div>
                      </Col>
                    </>
                  ) : showActionPrompts ? (
                    haveValue(user?.gurantor_for) &&
                    haveValue(user?.gurantor_for?._id) ? (
                      <Col md={6}>
                        <div className="Score-content-list">
                          <h4>{_t("Guarantor Of")}:</h4>
                          {user?.gurantor_for?.fname ? (
                            <h3>
                              {user?.gurantor_for?.fname}{" "}
                              {user?.gurantor_for?.lname}
                              <a
                                href={undefined}
                                onClick={handleRemoveGuarantor}
                              >
                                <svg
                                  width="7"
                                  height="13"
                                  viewBox="0 0 7 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 12L6 6.5L1 0.999999"
                                    stroke="#EF7833"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </a>
                            </h3>
                          ) : (
                            <h3 className="setNoInfoColor">
                              <a
                                href={undefined}
                                onClick={(e) =>
                                  navigate("/edit-profile/tenant")
                                }
                              >
                                {_t("+Add Info")}
                              </a>
                            </h3>
                          )}
                        </div>
                      </Col>
                    ) : (
                      <Col md={6}>
                        <a
                          href={undefined}
                          className="setNoInfoColor"
                          onClick={handleGurantorModal}
                        >
                          +{_t("Add Guarantor")}
                        </a>
                      </Col>
                    )
                  ) : null}
                </Row>
              </div>
            ) : null}
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ProfileInfoSection;
