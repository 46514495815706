import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import tickNew from "../../icons/tick-new.svg";
import { MdInfoOutline } from "react-icons/md";

const ProfessionalAcademicSection = (props) => {
  const { user, recordedSalary, showActionPrompts = false } = props;
  const _t = (val) => {
    return val;
  };
  const navigate = useNavigate();

  const haveValue = (val) => {
    if (
      val !== "" &&
      val !== "undefined" &&
      val !== undefined &&
      val !== null &&
      val !== "null"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getEmploymentStatus = () => {
    if (user?.employment_status == "employed") {
      return _t("Employed");
    }
    if (user?.employment_status == "self_employed") {
      return _t("Self-Employed");
    }
    if (user?.employment_status == "student") {
      return _t("Student");
    }
    if (user?.employment_status == "unemployed") {
      return _t("Unemployed");
    }
  };
  const checkPayslipData = (user, _t) => {
    if (
      haveValue(user?.payslip_data) &&
      user?.payslip_data?.nieDniNumber == user?.dni_nie
    ) {
      return (
        <>
          <Col md={6}>
            <div className="Score-content-list">
              <h4>{_t("Income from Payslip")}:</h4>€
              {convertCurrencyFormat(parseInt(user?.payslip_data?.netSalary))}
            </div>
          </Col>
        </>
      );
    } else {
      return (
        <Col md={6}>
          <div className="Score-content-list">
            <h4>{_t("Income from Payslip")}:</h4>
          </div>
        </Col>
      );
    }
  };
  const fetchCompanyName = (user, _t) => {
    var found = false;
    var res = null;
    if (haveValue(user?.vida_laboral_id)) {
      const response = user?.vida_laboral_id?.response;
      if (user?.dni_nie == response?.informacionPersonal?.identificacion) {
        const companyArr = user?.vida_laboral_id?.response?.situaciones;
        var company_name = null;
        for (let val of companyArr) {
          if (!haveValue(val?.fechaBaja)) {
            company_name = val?.nombreEmpresa;
            break;
          }
        }
        if (haveValue(company_name)) {
          found = true;
          res = { type: "Vida Laboral", val: company_name };
        }
      }
    }
    if (
      found == false &&
      haveValue(user?.algoanIncome?.employers) &&
      user?.algoanIncome?.employers.length > 0
    ) {
      found = true;
      res = { type: "Bank", val: user?.algoanIncome?.employers[0]?.name };
    }
    if (found == false && haveValue(user?.name_of_company)) {
      found = true;
      res = { type: "", val: user?.name_of_company };
    }
    if (found == false) {
      res = null;
    }
    if (haveValue(res)) {
      return (
        <>
          <h4 className="d-flex gap-2 align-items-center">
            {_t("Name Of Company")}
            {haveValue(res.type) && (
              <ul className="infodorpdown summarydropdum position-relative">
                <li>
                  <button className="iconinfo">
                    <MdInfoOutline />
                  </button>{" "}
                </li>
                <ul className="tooltip">
                  <p>
                    {_t("From - ")}
                    {res.type}
                  </p>
                </ul>
              </ul>
            )}
            :
          </h4>
          <h3>
            <span>{res.val}</span>{" "}
            {haveValue(res.type) && <img src={tickNew} alt="" />}
          </h3>
        </>
      );
    }
  };
  const convertCurrencyFormat = (amount) => {
    return new Intl.NumberFormat("en", {
      currency: "en" === "es" ? "EUR" : "USD",
    }).format(amount);
  };

  return (
    <>
      <Col xl={6} className="mb-3">
        <div className="score-professional border-pd accordion-tile">
          <h4>{_t("Professional and Academic")} </h4>
          <Row className="mx-0">
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("Employment Status")}:</h4>
                {user?.employment_status ? (
                  <h3>{getEmploymentStatus()}</h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            {user?.employment_status === "self_employed" && (
              <>
                <Col md={6}>
                  <div className="Score-content-list">
                    <h4>{_t("Employment Type")}:</h4>
                    {haveValue(user?.is_freelancer) ? (
                      <h3>
                        {user?.is_freelancer == true
                          ? _t("Freelancer")
                          : _t("Own a Company")}
                      </h3>
                    ) : (
                      <h3 className="setNoInfoColor">
                        {showActionPrompts ? (
                          <a
                            href={undefined}
                            onClick={(e) => navigate("/edit-profile/tenant")}
                          >
                            {_t("+Add Info")}
                          </a>
                        ) : (
                          <p href={undefined}>{_t("No Info")}</p>
                        )}
                      </h3>
                    )}
                  </div>
                </Col>
                {user?.is_freelancer == false && (
                  <Col md={6}>
                    <div className="Score-content-list">
                      <h4>{_t("Company Name")}:</h4>
                      {user?.name_of_company ? (
                        <h3>{user?.name_of_company}</h3>
                      ) : (
                        <h3 className="setNoInfoColor">
                          {showActionPrompts ? (
                            <a
                              href={undefined}
                              onClick={(e) => navigate("/edit-profile/tenant")}
                            >
                              {_t("+Add Info")}
                            </a>
                          ) : (
                            <p href={undefined}>{_t("No Info")}</p>
                          )}
                        </h3>
                      )}
                    </div>
                  </Col>
                )}
              </>
            )}
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("Highest Education")}:</h4>
                {user?.type_of_student ? (
                  <h3>{_t(user?.type_of_student)}</h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("University")}:</h4>
                {user?.name_of_university ? (
                  <h3>{user?.name_of_university}</h3>
                ) : (
                  <h3 className="setNoInfoColor">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>
            {user?.employment_status === "employed" && (
              <>
                <Col md={6}>
                  <div className="Score-content-list">
                    <h4>{_t("Position")}:</h4>
                    <h3>{user?.position} </h3>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="Score-content-list">
                    {fetchCompanyName(user, _t)}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="Score-content-list">
                    <h4>{_t("Email Of Previous Employer")}:</h4>
                    <h3>{user?.email_previous_employer} </h3>
                  </div>
                </Col>
              </>
            )}

            <Col md={6}>
              <div className="Score-content-list">
                <h4>{_t("LinkedIn Profile")}:</h4>
                {user?.linkedin_profile ? (
                  <h3>{user?.linkedin_profile}</h3>
                ) : (
                  <h3 className="setNoInfoColor text-start">
                    {showActionPrompts ? (
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/edit-profile/tenant")}
                      >
                        {_t("+Add Info")}
                      </a>
                    ) : (
                      <p href={undefined}>{_t("No Info")}</p>
                    )}
                  </h3>
                )}
              </div>
            </Col>

            <div className="row incomes_main_div p-3">
              <h4 style={{ textAlign: "left" }}>{_t("Income Details")}</h4>
              <Col md={6}>
                <div className="Score-content-list">
                  <h4>{_t("Declared income")}:</h4>
                  {user?.current_annual_income ? (
                    <h3>
                      €{convertCurrencyFormat(user?.current_annual_income)}
                    </h3>
                  ) : (
                    <h3 className="setNoInfoColor">
                      {showActionPrompts ? (
                        <a
                          href={undefined}
                          onClick={(e) => navigate("/edit-profile/tenant")}
                        >
                          {_t("+Add Info")}
                        </a>
                      ) : (
                        <p href={undefined}>{_t("No Info")}</p>
                      )}
                    </h3>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="Score-content-list">
                  <h4>{_t("Verified Income From Bank")}:</h4>
                  {haveValue(recordedSalary?.salary) &&
                  recordedSalary?.salary ? (
                    <h3>
                      €{convertCurrencyFormat(recordedSalary?.salary)}
                      <img src={tickNew} alt="" />
                    </h3>
                  ) : (
                    <h3 className="setNoInfoColor">{_t("No Info")}</h3>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="Score-content-list">
                  <h4>{_t("Verified Income from IRPF")}:</h4>
                  {haveValue(user?.itr_status) ? (
                    user?.itr_status == "success" ? (
                      <>
                        <h3>
                          €{convertCurrencyFormat(user?.itr_amount)}{" "}
                          <img src={tickNew} alt="" />
                        </h3>
                      </>
                    ) : user?.itr_status == "pending" && showActionPrompts ? (
                      <h3>{_t("In Process")}</h3>
                    ) : showActionPrompts ? (
                      <h3>{_t("Unable To Fetch The Amount")} </h3>
                    ) : null
                  ) : (
                    <></>
                  )}
                </div>
              </Col>

              {checkPayslipData(user, _t)}
            </div>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ProfessionalAcademicSection;
