import { useEffect, useState } from "react";
import {
  deleteLandlordDoc,
  fetchLandlordFiles,
  uploadLandlordFiles,
} from "../../context/functions/AdminFunction";
import { Card, Col, Modal, Row, Form } from "react-bootstrap";
import { BASE_URL } from "../../config";
import { VscEye } from "react-icons/vsc";
import moment from "moment";
import { RiDeleteBin6Line, RiDownloadLine } from "react-icons/ri";

const LandlordDocuments = (props) => {
  const { landlord } = props;
  const [fileData, setFileData] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [landlordDocs, setLandlordDocs] = useState(null);
  const [filesCountSelected, setFilesCountSelected] = useState(0);
  useEffect(() => {
    fetchDocs();
  }, []);

  const fetchDocs = () => {
    fetchLandlordFiles(landlord?._id)
      .then((res) => {
        console.log(res, "res for fetchLandlordFiles");
        setLandlordDocs(res.data.success_res.docs);
      })
      .catch((err) => console.log("Error", err));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    setFileData(formData);
    setFilesCountSelected(files.length);
  };

  const handleUploadFiles = () => {
    uploadLandlordFiles(fileData, landlord?._id)
      .then((res) => {
        fetchDocs();
        setFileData(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePreviewDocument = (url) => {
    window.open(url, "blank");
  };

  const handleDownloadDoc = async (url, filename) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      });
    });
  };

  const handleDeleteDoc = async (doc_id) => {
    try {
      const res = await deleteLandlordDoc(doc_id);
      await fetchDocs();
      setDeleteId(null);
      setDeleteDocumentModal(false);
    } catch (err) { }
  };

  console.log(landlordDocs, "CHECK THIS");

  return (
    <>
      <div className="col-6 uploade-page-doc-main">
        <p className="mb-1">Upload Document</p>
        <div className="row">
          <div className="col-9 doc-main-box">
            <label className="document-update-box form-control">
              {filesCountSelected === 0 ? 'Choose File' : <><span class="badge bg-success me-2">{filesCountSelected} </span> files selected</>}
              <input
                type="file"
                onChange={(e) => {
                  handleFileChange(e);
                }}
                multiple
                accept="application/pdf"
              />
            </label>
          </div>
          <div className="col-3 upload-btn-box pe-0">
            <button className="upload-doucmnet-btn" onClick={handleUploadFiles}>
              Upload
            </button>
          </div>
        </div>
      </div>
      <Row className="card-listing">
        {landlordDocs?.map((eachData, index) => (
          <Col md={3} key={eachData?._id}>
            <Card>
              <div className="videoframe">
                <iframe
                  src={`${BASE_URL}/uploads/${eachData?.file_name} `}
                  scrolling="no"
                />
              </div>
              <Card.Body>
                <div className="listing-head">
                  <div className="head-block">
                    <div className="leftbartext">
                      <h5>{eachData?.type}</h5>
                      <p>{eachData?.file_name.split("-").slice(1).join("-")}</p>
                    </div>
                    <VscEye
                      onClick={(e) =>
                        handlePreviewDocument(
                          `${BASE_URL}/uploads/${eachData?.file_name} `
                        )
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div className="listing-foot">
                  <span>
                    {moment(eachData?.createdAt).format("DD/MM/YYYY")}
                  </span>
                  <div className="iconssgroups">
                    <span
                      className="del-icon"
                      onClick={(e) =>
                        handleDownloadDoc(
                          `${BASE_URL}/uploads/${eachData?.file_name} `,
                          eachData?.file_name
                        )
                      }
                    >
                      <RiDownloadLine style={{ cursor: "pointer" }} />
                    </span>
                    <span
                      className="del-icon deleteicon"
                      onClick={(e) => {
                        setDeleteId(eachData?._id);
                        setDeleteDocumentModal(true);
                      }}
                    >
                      <RiDeleteBin6Line style={{ cursor: "pointer" }} />
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        show={deleteDocumentModal}
        onHide={(e) => {
          setDeleteDocumentModal(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to delete the document. It cannot be reversed.
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button onClick={(e) => setDeleteDocumentModal(false)}>Cancel</button>
          <button onClick={(e) => handleDeleteDoc(deleteId)}>Delete</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LandlordDocuments;
