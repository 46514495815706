import { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import { haveValue } from "../utils/common";
import { toast } from "react-toastify";
import {
  changeUserStatus,
  createSubAdmin,
  getAdminList,
} from "../context/functions/AdminFunction";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

const AddAdmin = () => {
  const defaultState = {
    email: "",
    password: "",
    role: "",
    fname: "",
    lname: "",
    type: "admin",
  };
  const [adminData, setAdminData] = useState(defaultState);
  const [showPass, setShowPass] = useState(false);
  const [adminList, setAdminList] = useState([]);

  useEffect(() => {
    getFullAdminList();
  }, []);

  const getFullAdminList = () => {
    getAdminList()
      .then((res) => {
        console.log(res);
        setAdminList(res.admins);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminData((data) => ({ ...data, [name]: value }));
  };

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    toast.error("Invalid email address");
    return false;
  };

  let strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );

  const handleCreateAdmin = (e) => {
    e.preventDefault();
    if (
      !haveValue(adminData.email) ||
      !haveValue(adminData.role) ||
      !haveValue(adminData.password)
    ) {
      console.log("COMING IN 1");
      toast.error("All fields are mandatory!");
      return false;
    }

    if (!validateEmail(adminData.email)) {
      return false;
    }
    if (!strongPassword.test(adminData.password)) {
      toast.error(
        "Please include Uppercase lowercase and special characters in your password and must be atleast 8 character long."
      );
    }
    createSubAdmin(adminData)
      .then((res) => {
        setAdminData(defaultState);
        getFullAdminList();
      })
      .catch((err) => {
        console.log(err, "check err here");
      });
  };

  const handleShowPassword = (val) => {
    setShowPass(val);
  };

  const handleChangeStatus = (_id) => {
    const data = {
      user_id: _id,
      role: "admin",
    };
    changeUserStatus(data)
      .then((res) => {
        getFullAdminList();
        toast.success("Status changed successfully.");
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log("change status hit");
  };

  console.log(adminData);

  return (
    <>
      <div className="header_table d-flex justify-content-between align-items-center">
        <h1>Admin Control</h1>
        <div className="searchbar_table">
          <div className="d-flex align-items-center gap-4"></div>
        </div>
      </div>
      <div>
        <form
          onSubmit={handleCreateAdmin}
          className="d-flex justify-content-center"
        >
          <div className="create-admin-div">
            <h3>Add New Admin</h3>
            <div className="row w-100">
              <label htmlFor="" className="col-6">
                First Name
                <InputGroup className="input_table_header">
                  <Form.Control
                    placeholder="First Name"
                    aria-label="First Name"
                    name="fname"
                    aria-describedby="basic-addon1"
                    type="text"
                    value={adminData.fname}
                    onChange={handleInputChange}
                  />
                </InputGroup>{" "}
              </label>
              <label htmlFor="" className="col-6">
                Last Name
                <InputGroup className="input_table_header">
                  <Form.Control
                    placeholder="Last Name"
                    aria-label="Last Name"
                    name="lname"
                    aria-describedby="basic-addon1"
                    type="text"
                    value={adminData.lname}
                    onChange={handleInputChange}
                  />
                </InputGroup>{" "}
              </label>
            </div>
            <div className="row w-100">
              <label htmlFor="" className="col-6">
                Email
                <InputGroup className="input_table_header">
                  <Form.Control
                    placeholder="email"
                    aria-label="Email"
                    name="email"
                    aria-describedby="basic-addon1"
                    type="email"
                    value={adminData.email}
                    onChange={handleInputChange}
                  />
                </InputGroup>{" "}
              </label>
              <label htmlFor="" className="col-6">
                Password
                <InputGroup className="input_table_header">
                  <Form.Control
                    placeholder="Password"
                    aria-label="Password"
                    name="password"
                    aria-describedby="basic-addon1"
                    type={showPass ? "text" : "password"}
                    value={adminData.password}
                    onChange={handleInputChange}
                  />
                  {showPass ? (
                    <VscEye
                      className="eye-icons"
                      onClick={(e) => handleShowPassword(false)}
                    />
                  ) : (
                    <VscEyeClosed
                      className="eye-icons"
                      onClick={(e) => handleShowPassword(true)}
                    />
                  )}
                </InputGroup>
              </label>
            </div>

            {/* <label htmlFor="">Email</label>
            <InputGroup className="input_table_header">
              <Form.Control
                placeholder="email"
                aria-label="Email"
                name="email"
                aria-describedby="basic-addon1"
                type="email"
                value={adminData.email}
                onChange={handleInputChange}
              />
            </InputGroup>{" "}
            <label htmlFor="">Password</label>
            <InputGroup className="input_table_header">
              <Form.Control
                placeholder="Password"
                aria-label="Password"
                name="password"
                aria-describedby="basic-addon1"
                type={showPass ? "text" : "password"}
                value={adminData.password}
                onChange={handleInputChange}
              />
              {showPass ? (
                <VscEye
                  className="eye-icons"
                  onClick={(e) => handleShowPassword(false)}
                />
              ) : (
                <VscEyeClosed
                  className="eye-icons"
                  onClick={(e) => handleShowPassword(true)}
                />
              )}
            </InputGroup> */}
            <div className="row w-100">
              <div className="col-12">
                <label htmlFor="">Role</label>
                <Form.Select
                  aria-label="Select Role"
                  value={adminData.role}
                  name="role"
                  onChange={handleInputChange}
                >
                  <option value={null}>Choose Role</option>
                  <option value="super_admin">Super Admin</option>
                  <option value="admin">Admin</option>
                </Form.Select>
              </div>
            </div>
            {/* <label htmlFor="">Role</label>
            <Form.Select
              aria-label="Select Role"
              value={adminData.role}
              name="role"
              onChange={handleInputChange}
            >
              <option value={null}>Choose Role</option>
              <option value="super_admin">Super Admin</option>
              <option value="admin">Admin</option>
            </Form.Select> */}
            <button>Create Admin</button>
          </div>
        </form>
      </div>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {adminList &&
            adminList.map((val, index) => (
              <tr key={index}>
                <td>
                  {val.fname} {val.lname}
                </td>
                <td>{val.email}</td>
                <td>{val.role}</td>
                <td>{val.status ? "Active" : "In Active"}</td>
                <td>
                  <a href={null} onClick={(e) => handleChangeStatus(val?._id)}>
                    Change Status
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {/* <div className="">
        <button
          onClick={() => handlePageChange(+currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => handlePageChange(+currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div> */}
    </>
  );
};

export default AddAdmin;
