import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../config";
import { Col, Row } from "react-bootstrap";

const LandLordCard = (props) => {
  const { landlord, properties, sharedProfile } = props;

  const [filteredProperties, setFilteredProperties] = useState([]);

  useEffect(() => {
    const getPropertyIdsFromSharedProfiles = (sharedProfiles) => {
      return sharedProfiles.reduce((acc, profile) => {
        return acc.concat(profile.propertyid.map((property) => property._id));
      }, []);
    };

    const propertyIdsFromSharedProfiles =
      getPropertyIdsFromSharedProfiles(sharedProfile);

    const filteredProperties2 = properties.filter((property) => {
      return propertyIdsFromSharedProfiles.some((id) => id === property._id);
    });

    const filteredProperties1 = filteredProperties2.map(
      (property) => property._id
    );

    setFilteredProperties(filteredProperties1);
  }, [sharedProfile]);

  return (
    <>
      <div className="landlord-deatail-tabel-main">
        <div className="row">
          <div className="col-md-6">
            <ul>
              <li>
                <h6>Name:</h6>
                <p>
                  {landlord?.fname_companyName} {landlord?.lname}
                </p>
              </li>
              <li>
                <h6>Ph.no:</h6>
                {landlord?.phone}
              </li>
              <li>
                <h6>Type:</h6>
                {landlord?.landlordtype}
              </li>
              <li>
                <h6>CIF:</h6>
                {landlord?.cif_dni_nie}
              </li>
              <li>
                <h6>Linked Properties:</h6>
                {filteredProperties.length}
              </li>
              <li>
                <h6>No. of Properties:</h6>
                {properties.length}
              </li>
              {landlord?.isLandlordInComp == "company" && (
                <>
                  <li>
                    <h6>Company:</h6>
                    <p> {landlord?.company_name}</p>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="col-md-6">
            {landlord?.isLandlordInComp == "company" && (
              <>
                <div className="tabel-companey-logo">
                  <h6>Company Logo:</h6>
                  <div className="tabel-logo-image">
                    <img
                      src={`${BASE_URL}/uploads/${landlord?.company_logo} `}
                      alt=""
                      height="100px"
                      width="100px"
                    />{" "}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <div className="d-flex card">
        <div className="card-body crd_card d-flex justify-content-between align-items-center flex-wrap">
          <p>
            <span>Name:</span>
            <br></br>
            {landlord?.fname_companyName} {landlord?.lname}
          </p>
          <p>
            <span>Ph.no:</span>
            <br></br>
            {landlord?.phone}
          </p>
          <p>
            <span>Type:</span>
            <br></br>
            {landlord?.landlordtype}
          </p>
          <p>
            <span>CIF:</span>
            <br></br>
            {landlord?.cif_dni_nie}
          </p>
          <p>
            <span>Linked Properties</span>
            <br></br>
            {filteredProperties.length}
          </p>
          <p>
            <span>No. of Properties</span>
            <br></br>
            {properties.length}
          </p>
          {landlord?.isLandlordInComp == "company" && (
            <>
              <p>
                <span>Company:</span>
                <br></br>
                {landlord?.company_name}
              </p>
              <p>
                <span>Company Logo:</span>
                <br></br>
                <img
                  src={`${BASE_URL}/uploads/${landlord?.company_logo} `}
                  alt=""
                  height="100px"
                  width="100px"
                />
              </p>
            </>
          )}
          <p>
            <span>Email:</span>
            <br></br>
            {landlord?.email}
          </p>
        </div>
      </div> */}
    </>
  );
};

export default LandLordCard;
