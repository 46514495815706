import React, { useEffect, useState, useContext } from "react";
import Table from "react-bootstrap/esm/Table";
import { getWaitlist } from "../context/functions/AdminFunction";
import { Form, InputGroup } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";
import CsvDownloadButton from "react-json-to-csv";
import ReactPaginate from "react-paginate";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import Loading from "../components/loader/Loading";
const Waitlist = (props) => {
  const [waitlist, setWaitlist] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [landlordList, setLandlordList] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState("Latest");
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWaitlistFunc();
  }, [currentPage, searchVal]);

  const getWaitlistFunc = async () => {
    setLoading(true);
    const temp = await getWaitlist(searchVal, currentPage);

    setTotalPages(temp?.totalPages);
    setWaitlist(temp?.waitlist);

    setLoading(false);
  };

  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearchVal(e.target.value);
    setcurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setcurrentPage(event.selected + 1);
  };

  const handleDownloadData = async () => {
    console.log("Function has been called!!");
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="waitlist-main">
        <div className="header_table d-flex justify-content-between align-items-center">
          <h1>Zero Deposit Waitlist</h1>
          <div className="searchbar_table">
            <div className="d-flex align-items-center gap-4">
              <CsvDownloadButton
                data={waitlist}
                className="border-0"
                hover="Download List"
              >
                <FaFileDownload />
              </CsvDownloadButton>
              <InputGroup className="input_table_header">
                <InputGroup.Text id="basic-addon1">
                  <HiMagnifyingGlass />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={searchVal}
                  onChange={handleSearch}
                />
              </InputGroup>
            </div>

            {/* <OverlayTrigger trigger="click" placement="bottom" overlay={filter1()}>
                        <button>
                            <GiSettingsKnobs size={22} color='var(--dark)' />
                        </button>
                    </OverlayTrigger> */}
          </div>
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Added On</th>
            </tr>
          </thead>
          <tbody>
            {waitlist &&
              waitlist.map((val, index) => (
                <tr key={index}>
                  <td>{val.email}</td>
                  <td>{val.created_at}</td>
                </tr>
              ))}

            {waitlist?.length === 0 && (
              <tr>
                <td colSpan={2} className="">
                  No Data Found!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <ReactPaginate
          nextLabel={<MdNavigateNext />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={totalPages}
          previousLabel={<GrFormPrevious />}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage - 1}
        />
        {/* <div className="">
          <button
            onClick={() => handlePageChange(+currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={() => handlePageChange(+currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next  
          </button>
        </div> */}
      </div>
    </>
  );
};

export default Waitlist;
