import { Grid } from "react-loader-spinner";

const Loading = ({ type }) => {
  return (
    <div className="global-loader">
      <div className="child-loader">
        <Grid
          height="80"
          width="80"
          color="#a3ec64"
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  );
};

export default Loading;
