exports.haveValue = (val) => {
  if (
    val !== "" &&
    val !== "undefined" &&
    val !== undefined &&
    val !== null &&
    val !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};

exports.getClassName = (value) => {
  if (value) {
    return "green";
  } else {
    return "red";
  }
};
