import { Col, Row, Spinner } from "react-bootstrap";
import tickNew from "../../icons/tick-new.svg";
import crossNew from "../../icons/cross-new.svg";
import exclamation from "../../icons/exclamation.png";
import { MdInfoOutline, MdOutlineVerified } from "react-icons/md";
import { useContext, useState } from "react";
import axios from "axios";
import { BsBank2 } from "react-icons/bs";
import { Link } from "react-router-dom";

const SummarySection = (props) => {
  const {
    user,
    showActionPrompts = false,
    ident_all,
    recordedSalary,
    bankhandleShow = (e) => {
      console.log("Only available for Tenant");
    },
    haveGuarantor,
    rentDoc = false,
    setRentDoc = (e) => console.log("ONLY AVAILABLE FOR LANDLORD"),
    rentPercentage = 0,
    setRentPercentage = (e) => console.log("ONLY AVAILABLE FOR LANDLORD"),
    rentValFrom = null,
    isProfilePreview = false,
    handleViewDocument = (e) => {
      console.log("ADMINS IDE");
    },
  } = props;
  const queryParameters = new URLSearchParams(window.location.search);
  const _t = (val) => {
    return val;
  };
  const marks = [
    {
      value: 1,
      label: "1%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  const [showSpinner, setShowSpinner] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const haveValue = (val) => {
    if (
      val !== "" &&
      val !== "undefined" &&
      val !== undefined &&
      val !== null &&
      val !== "null"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const convertCurrencyFormat = (amount) => {
    return new Intl.NumberFormat("en", {
      currency: "en" === "es" ? "EUR" : "USD",
    }).format(amount);
  };

  const initiateOnfido = () => {
    console.log("Only for tenant side");
  };
  const checkIeDetails = () => {
    var check = true;

    if (!haveValue(user?.ie_details_id)) {
      check = false;
      return check;
    }
    // if (user?.fname?.toLowerCase().replace(/\u00A0/g, ' ') != user?.ie_details_id?.fname?.toLowerCase().replace(/\u00A0/g, ' ')) {
    //   check = false;
    // }
    // if (user?.lname?.toLowerCase().replace(/\u00A0/g, ' ') != user?.ie_details_id?.lname?.toLowerCase().replace(/\u00A0/g, ' ')) {
    //   check = false
    // }
    if (user?.ie_student_id != user?.ie_details_id?.student_id) {
      check = false;
    }
    // if (user?.current_course.replace(/\u00A0/g, ' ') != user?.ie_details_id?.course_title.replace(/\u00A0/g, ' ')) {
    //   check = false
    // }
    return check;
  };

  return (
    <>
      <Col xl={6} className="mb-3">
        <div className="score-summary border-pd prime-summary-sec accordion-tile">
          <div className={isProfilePreview && "row"}>
            <h4 className="">
              {isProfilePreview ? _t("Summary & Profile QR") : _t("Summary")}
            </h4>
            <div
              className={`summary-checkes ${
                isProfilePreview && "col-12 col-md-6"
              }`}
            >
              {isProfilePreview && (
                <h3 className="summary-sub-heading">{_t("Your Summary")}:</h3>
              )}
              <label className="rounded-check summaryinfobar padding10">
                {user?.has_profile == true ? (
                  <img src={tickNew} alt="" />
                ) : (
                  <img src={crossNew} alt="" />
                )}
                {_t("Completed Profile")}
              </label>
              <label
                className={`rounded-check summaryinfobar flexbarinner ${
                  isProfilePreview && "padding10"
                }`}
              >
                {/* --------------------new responsive css (heena)----------------- */}
                {ident_all == true ? (
                  <img src={tickNew} alt="" />
                ) : user?.onfido_user_kyc?.onfido_doc &&
                  user?.onfido_user_kyc?.onfido_workflow_res?.status !=
                    "declined" ? (
                  <img src={exclamation} alt="" />
                ) : (
                  <img src={crossNew} alt="" />
                )}
                {_t("Verified identification")}
                {/* --------------------new responsive css (heena)-----------------  */}
                {user?.onfido_kyc_success == false &&
                  user?.onfido_user_kyc?.onfido_doc &&
                  user?.onfido_user_kyc?.onfido_workflow_res?.status !=
                    "declined" &&
                  showActionPrompts &&
                  !isProfilePreview && (
                    <ul className="infodorpdown summarydropdum">
                      <li>
                        <button className="iconinfo">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <p>{_t("Verification in process")}</p>
                      </ul>
                    </ul>
                  )}
                {user?.onfido_kyc_success == false &&
                  !haveValue(user?.onfido_user_kyc?.onfido_doc) &&
                  showActionPrompts &&
                  !isProfilePreview && (
                    <ul className="infodorpdown summarydropdum">
                      <li>
                        <button className="iconinfo">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <li>
                          {_t(
                            "You haven’t completed your digital verification yet"
                          )}
                          .
                        </li>
                      </ul>
                    </ul>
                  )}
                {user?.onfido_user_kyc?.onfido_doc &&
                  user?.onfido_user_kyc?.onfido_workflow_res?.status !=
                    "declined" &&
                  !isProfilePreview && (
                    <Col
                      md={6}
                      className="d-flex gap-2 align-items-center justify-content-end"
                    >
                      {!isProfilePreview &&
                        (showActionPrompts ? (
                          <button
                            type="button"
                            href={undefined}
                            onClick={handleViewDocument}
                            className="rounded-check summaryinfobar btn btngreen view_doc_btn"
                            id="viewDocumentBtn"
                          >
                            {_t("View Document")}
                          </button>
                        ) : (
                          <a
                            href={undefined}
                            onClick={handleViewDocument}
                            id="viewDocumentBtn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M9 13.5L3.375 7.875L4.95 6.24375L7.875 9.16875V0H10.125V9.16875L13.05 6.24375L14.625 7.875L9 13.5ZM2.25 18C1.63125 18 1.10175 17.7799 0.6615 17.3396C0.22125 16.8994 0.00075 16.3695 0 15.75V12.375H2.25V15.75H15.75V12.375H18V15.75C18 16.3687 17.7799 16.8986 17.3396 17.3396C16.8994 17.7806 16.3695 18.0007 15.75 18H2.25Z"
                                fill="#EF7833"
                              />
                            </svg>
                          </a>
                        ))}
                      {showSpinner && (
                        <Spinner className="view_document_spinner" />
                      )}
                    </Col>
                  )}
                {(!haveValue(user?.onfido_kyc_success) ||
                  user?.onfido_kyc_success == false) &&
                !haveValue(user?.onfido_user_kyc?.onfido_doc) &&
                showActionPrompts &&
                !isProfilePreview ? (
                  <Col
                    md={6}
                    className="d-flex gap-2 align-items-center justify-content-end"
                  >
                    <button
                      className="btn btnorange w-100 view_doc_btn"
                      type="button"
                      disabled={buttonDisable}
                      onClick={initiateOnfido}
                      id="verify_now_btn"
                    >
                      {_t("Verify Now")}
                      <MdOutlineVerified />
                    </button>
                    {showSpinner && (
                      <Spinner className="view_document_spinner" />
                    )}
                  </Col>
                ) : (
                  <></>
                )}
              </label>
              <label className="rounded-check summaryinfobar padding10">
                {haveValue(user?.gurantor_id) ? (
                  haveValue(user?.gurantor_id?.paymentScores) ? (
                    <img src={tickNew} alt="" />
                  ) : (
                    <img src={crossNew} alt="" />
                  )
                ) : haveValue(user?.paymentScores) ? (
                  <img src={tickNew} alt="" />
                ) : (
                  <img src={crossNew} alt="" />
                )}
                {_t("Verified bank account")}
              </label>
              <label className="rounded-check summaryinfobar padding10">
                {recordedSalary?.matched >= 80 ? (
                  <img src={tickNew} alt="" />
                ) : (
                  <img src={crossNew} alt="" />
                )}
                {_t("Verified income")}
                {recordedSalary?.matched < 80 && !isProfilePreview ? (
                  <ul className="infodorpdown summarydropdum">
                    <li>
                      <button className="iconinfo">
                        <MdInfoOutline />
                      </button>{" "}
                    </li>
                    <ul className="tooltip">
                      <li>
                        <p>
                          {_t("Income")} {recordedSalary?.matched}{" "}
                          {_t(
                            "% Match with the Annual Income recorded from bank account"
                          )}
                        </p>
                        {showActionPrompts && (
                          <button
                            className="btnupdate"
                            onClick={bankhandleShow}
                          >
                            <BsBank2 />
                            {_t("Update Bank")}
                          </button>
                        )}
                      </li>
                    </ul>
                  </ul>
                ) : (
                  (haveValue(user?.gurantor_id?.current_annual_income) ||
                    haveValue(user?.current_annual_income)) &&
                  !isProfilePreview && (
                    <ul className="infodorpdown summarydropdum">
                      <li>
                        <button className="iconinfo">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <li>
                          <p>
                            {_t("Income")} {recordedSalary?.matched}{" "}
                            {_t(
                              "% Match with the Annual Income recorded from bank account"
                            )}
                          </p>
                        </li>
                      </ul>
                    </ul>
                  )
                )}
              </label>
              {user?.have_gurantor && user?.have_gurantor == "true" && (
                <label className="rounded-check summaryinfobar padding10">
                  {haveGuarantor == true || haveGuarantor == "true" ? (
                    <img src={tickNew} alt="" />
                  ) : (
                    <img src={crossNew} alt="" />
                  )}
                  {_t("Guarantor")}
                  <ul className="infodorpdown summarydropdum">
                    <li>
                      <button className="iconinfo">
                        <MdInfoOutline />
                      </button>{" "}
                    </li>
                    <ul className="tooltip">
                      <p>
                        {haveValue(user?.gurantor_id) &&
                        haveValue(user?.gurantor_id?.paymentScores)
                          ? _t("The score you see is of the Guarantor")
                          : showActionPrompts
                          ? _t(
                              "Guarantor added. Their score will be visible after bank details are provided."
                            )
                          : null}
                      </p>
                    </ul>
                  </ul>
                </label>
              )}
              {haveValue(user?.ie_details_id) && user?.is_university && (
                <label className="rounded-check summaryinfobar padding10">
                  {checkIeDetails(user) ? (
                    <img src={tickNew} alt="" />
                  ) : (
                    <img src={crossNew} alt="" />
                  )}
                  {_t("Verified")}
                  {checkIeDetails(user) &&
                    !showActionPrompts &&
                    !isProfilePreview && (
                      <ul className="infodorpdown summarydropdum">
                        <li>
                          <button className="iconinfo">
                            <MdInfoOutline />
                          </button>{" "}
                        </li>
                        <ul className="tooltip">
                          <li>
                            <p>
                              {_t(
                                "This tenant has been verified as an IE student and assigned an automatic Skor. The Skor will change when connecting the bank account and/or adding a guarantor."
                              )}
                            </p>
                          </li>
                        </ul>
                      </ul>
                    )}
                  <img
                    src="/images/ie_logo.svg"
                    /* --------------------new responsive css (heena)----------------- */
                    height="2.20vw"
                    width="2.20vw"
                    alt=""
                  />
                </label>
              )}
            </div>
          </div>
          {user?.onfido_user_kyc?.onfido_workflow_res?.status &&
            user?.onfido_user_kyc?.onfido_workflow_res?.status == "declined" &&
            showActionPrompts && (
              <div className="gurantor_box kyc_failed_div">
                <p
                  className="rounded-check set_gap_2"
                  style={{ textAlign: "center" }}
                >
                  <img src={crossNew} alt="" />
                  {_t("Your identity verification failed")}!
                </p>
                <Row>
                  <>
                    <Col md={6}>
                      {haveValue(user?.onfido_user_kyc?.onfido_error) &&
                        haveValue(user?.onfido_user_kyc?.onfido_error[0]) && (
                          <p
                            className="rounded-check"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {user?.onfido_user_kyc?.onfido_error[0]}
                          </p>
                        )}
                    </Col>
                    <Col md={6}>
                      <p
                        className="rounded-check"
                        style={{
                          textAlign: "center",
                          textDecoration: "underline",
                        }}
                      >
                        <Link to={undefined} onClick={initiateOnfido}>
                          {_t("Click Here To Retry")}
                        </Link>
                      </p>
                    </Col>
                  </>
                </Row>
              </div>
            )}
        </div>
      </Col>
    </>
  );
};

export default SummarySection;
