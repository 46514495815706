import React, { useState, useContext, useEffect } from "react";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { GiSettingsKnobs } from "react-icons/gi";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { FaPen } from "react-icons/fa";

import {
  InputGroup,
  Dropdown,
  Popover,
  OverlayTrigger,
  Form,
  Modal,
} from "react-bootstrap";
import LandLordCard from "../components/landlord/LandLordCard";
import Properties from "../components/landlord/Properties";
import { Ucontext } from "../context/Ucontext";
import {
  landlordLoadSharedProfiles,
  updateCompanyData,
} from "../context/functions/AdminFunction";
import toast from "react-hot-toast";
import LandlordDocuments from "../components/landlord/LandlordDocuments";
import { BASE_URL } from "../config";

const LandLordDetail = () => {
  const { landlordid } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const openEditModal = queryParameters.get("editCompany") || false;
  const {
    adminDispatch,
    adminState,
    getSingleUser,
    getLandlordProperties,
    getLandlordPropertyDetails,
    updateUserInfo,
  } = useContext(Ucontext);
  const { totalLandlords, totalProperties } = adminState;

  const [loading, setLoading] = useState(false);
  const [landlord, setLandlord] = useState({});
  const [properties, setProperties] = useState([]);
  const [filteredProp, setFilteredProp] = useState([]);
  const [propertiesDetails, setPropertiesDetails] = useState([]);
  const [sharedProfile, setSharedProfiles] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [iban_num, setIban_num] = useState("");
  const [showIban, setShowIban] = useState(false);
  const [showEditDetails, setShowEditDetails] = useState(openEditModal);
  const [editDetailsData, setEditDetailsData] = useState({
    file: landlord?.company_logo,
    company_name: landlord?.company_name,
  });
  const [companyImagePreview, setCompanyImagePreview] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    loadLandlord();
    loadProperties();
    navigate(`/landlorddetail/${landlordid}`);
  }, []);

  useEffect(() => {
    setIban_num(landlord?.iban_num);
    setEditDetailsData({
      file: landlord?.company_logo,
      company_name: landlord?.company_name,
    });
  }, [landlord]);

  useEffect(() => {
    const temp = propertiesDetails.filter((property) => {
      const addr = `${property?.street} ${property?.street_number} ${property?.floor_number} ${property?.flat_number} ${property?.postcode} ${property?.muncipality}`;
      if (addr.toLowerCase().includes(searchVal.toLowerCase())) return property;
    });
    console.log(temp);
    setFilteredProp(temp);
  }, [searchVal]);

  const loadLandlord = async () => {
    setLoading(true);
    let landlord = await getSingleUser(landlordid, "landlord");
    setLandlord(landlord);
    setLoading(false);
  };

  const loadProperties = async () => {
    setLoading(true);
    let landlord = await getLandlordProperties(landlordid);
    let details = await getLandlordPropertyDetails(landlordid);
    let sharedProfile1 = await landlordLoadSharedProfiles(landlordid);
    console.log(sharedProfile1);
    setSharedProfiles(sharedProfile1);
    setProperties(landlord?.properties);
    setFilteredProp(details?.properties);
    setPropertiesDetails(details?.properties);
    setLoading(false);
  };

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  const handleChangeIBAN = (e) => {
    setIban_num(e.target.value);
  };

  const saveIban = () => {
    updateUserInfo({ iban_num }, landlordid, "landlord")
      .then((res) => {
        toast.success("Landlord Updated");
        setShowIban(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadFile = (e) => {
    const files = e.target.files;
    let file = files[0];
    // Check file size
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    if (file.size > maxSizeInBytes) {
      alert("File size exceeds maximum limit (2MB)");
      return false;
    }

    //create file reader object
    const reader = new FileReader();
    //Listen to the "load" event and get the result (base64 encoded string)
    reader.onload = (e) => {
      setCompanyImagePreview(e.target.result);
    };
    //Read the file as data URL (base64 format)
    reader.readAsDataURL(file);

    setEditDetailsData((prev) => ({ ...prev, file: file }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditDetailsData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateCompany = () => {
    const formData = new FormData();
    formData.append("company_name", editDetailsData?.company_name);
    formData.append("file", editDetailsData?.file);
    updateCompanyData(formData, landlord?._id)
      .then((res) => {
        console.log(res, "Response for updating company details");
        if (res.status == 200) {
          setShowEditDetails(false);
          loadLandlord();
        }
      })
      .catch((err) => {
        console.log(err, "Error while updating Company Details");
      });
  };

  console.log(editDetailsData, "editDetailsDataeditDetailsData", landlord);

  if (loading) return <h1>Loading...</h1>;
  return (
    <>
      <NavLink to="/landlord">
        <IoIosArrowBack color="var(--dark)" size={25} />
      </NavLink>
      <div className="property_text d-flex justify-content-between">
        <div className="header_table">
          <h1>Details</h1>
        </div>
      </div>
      <div className="verticle_gap_in_components">
        <div className=" vertical-gap-input-box">
          <input
            type="text"
            id="iban_num"
            name="iban_num"
            value={iban_num}
            onChange={handleChangeIBAN}
            disabled={!showIban}
          />
          {showIban ? (
            <>
              <button className="me-1 ms-1" onClick={saveIban}>
                Save
              </button>
              <button className="me-1" onClick={(e) => setShowIban(false)}>
                Cancel
              </button>
            </>
          ) : (
            <button className="me-1 ms-1" onClick={(e) => setShowIban(true)}>
              Edit
            </button>
          )}
        </div>
        {landlord?.isLandlordInComp == "company" && (
          <div className="landlorad-detail-page-head">
            <button
              className="btn lanloard-admin-head-btn"
              onClick={(e) => setShowEditDetails(true)}
            >
              Edit Company Details
            </button>
            <div className="searchbar_table">
              <div>
                <InputGroup className="input_table_header">
                  <InputGroup.Text id="basic-addon1">
                    <HiMagnifyingGlass />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={searchVal}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        )}
        <LandLordCard
          landlord={landlord}
          properties={properties}
          sharedProfile={sharedProfile}
        />
        <Properties sharedProfile={sharedProfile} properties={filteredProp} />
        {landlord?.isLandlordInComp == "company" && (
          <LandlordDocuments landlord={landlord} />
        )}

        <Modal
          show={showEditDetails}
          onHide={(e) => {
            setShowEditDetails(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Change Company Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="edit_form_details company-edit-profile row">
              <div className="logo-main-box col-3 m-auto">
                <p>Company Logo</p>
                <div className="company-logo-box">
                  <div className="image-box">
                    <img
                      src={
                        companyImagePreview
                          ? companyImagePreview
                          : `${BASE_URL}/uploads/${landlord?.company_logo} `
                      }
                      alt="Company Logo"
                    />
                  </div>
                  <label className="file-update-btn">
                    <FaPen />
                    <input
                      type="file"
                      name="company_logo"
                      accept="image/png, image/jpeg"
                      // value={editDetailsData?.file}
                      onChange={(e) => handleUploadFile(e)}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="company-name-box col-6">
                <p>Company Name</p>
                <input
                  type="text"
                  value={editDetailsData?.company_name}
                  name="company_name"
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <LandlordDocuments landlord={landlord} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="doc-footer-btn" onClick={handleUpdateCompany}>
              Update
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default LandLordDetail;
