import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Loading from "../components/loader/Loading";
import { loadPotentialLandlords } from "../context/functions/AdminFunction";
import ReactPaginate from "react-paginate";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

const PotentialLandlord = () => {
  const [potentialLandlords, setPotentialLandlords] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPotentialLandlordsFunc = async () => {
      setLoading(true);
      const response = await loadPotentialLandlords("rentalinfos", currentPage);
      setPotentialLandlords(response?.potentials_landlords);
      setTotalPages(response?.total_pages);
      setLoading(false);
    };

    getPotentialLandlordsFunc();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setcurrentPage(event.selected + 1);
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="header_table d-flex justify-content-between align-items-center">
        <h1>Potential Landlords</h1>
      </div>
      <Table responsive hover>
        <thead className="patentiallanlord-admin-head">
          <th>Landlord Name</th>
          <th>Landlord Email</th>
          <th>Landlord Account Number</th>
          <th>Number Of Times Rent Paid</th>
        </thead>
        <tbody>
          {potentialLandlords.length > 0 &&
            potentialLandlords.map((potentialLandlord) => {
              return (
                <tr>
                  <td>{potentialLandlord.landlord_fname}</td>
                  <td>{potentialLandlord.landlord_email}</td>
                  <td>{potentialLandlord.landlord_account_number}</td>
                  <td>{potentialLandlord.count}</td>
                </tr>
              );
            })}
          {potentialLandlords?.length === 0 && (
            <tr>
              <td colSpan={4} className="">
                No Data Found!
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <ReactPaginate
        nextLabel={<MdNavigateNext />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={totalPages}
        previousLabel={<GrFormPrevious />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={currentPage - 1}
      />
      {/* <div className="">
                <button
                    onClick={() => handlePageChange(+currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>{`Page ${currentPage} of ${totalPages}`}</span>
                <button
                    onClick={() => handlePageChange(+currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div> */}
    </>
  );
};

export default PotentialLandlord;
